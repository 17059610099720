export const logoNegative = ['608 134', ` 
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="94">
<path d="M0 0 C1.79296875 0.04711914 1.79296875 0.04711914 4 1 C5.30940563 3.43090849 6.32846006 5.68580053 7.3125 8.24609375 C7.60809814 8.97240494 7.90369629 9.69871613 8.20825195 10.44703674 C9.15350675 12.77159415 10.07872937 15.10334222 11 17.4375 C11.92184002 19.76781353 12.84812071 22.09566858 13.79174805 24.41725159 C14.37905193 25.86221082 14.95392195 27.31230011 15.51489258 28.76768494 C17.10777008 32.69674437 17.10777008 32.69674437 20.20703125 35.46118164 C20.79871094 35.6389917 21.39039063 35.81680176 22 36 C22 36.66 22 37.32 22 38 C16.72 38 11.44 38 6 38 C6 37.34 6 36.68 6 36 C7.98 35.505 7.98 35.505 10 35 C5.545 24.11 5.545 24.11 1 13 C-1.59628479 15.2981424 -1.59628479 15.2981424 -3 21 C-0.03 23.97 2.94 26.94 6 30 C5.505 31.485 5.505 31.485 5 33 C4.01 32.505 4.01 32.505 3 32 C3 31.34 3 30.68 3 30 C2.443125 29.731875 1.88625 29.46375 1.3125 29.1875 C-1.19712523 27.89877353 -3.59334236 26.47073522 -6 25 C-6.99 28.3 -7.98 31.6 -9 35 C-7.68 35.33 -6.36 35.66 -5 36 C-5 36.66 -5 37.32 -5 38 C-9.29 38 -13.58 38 -18 38 C-17 35 -17 35 -15.125 33.375 C-12.11911044 30.01547637 -11.20792344 26.28263765 -10 22 C-16.09343123 19.23025853 -20.44626385 18.41011216 -27 20 C-31.06912395 21.58243709 -34.05200247 22.99578286 -36 27 C-36.39513222 31.14888832 -36.37099324 33.88702029 -35.0625 37.8125 C-31.85388448 41.21557707 -28.61370483 42.39963105 -24 43 C-19.58787875 42.66327245 -15.32349884 41.9288506 -11 41 C-14.95211827 45.16574628 -17.41373101 47.11833786 -23.1875 47.3125 C-29.81800164 47.21546827 -34.12059244 44.71945798 -38.9375 40.3125 C-41.53728085 36.13709439 -42.41555791 32.90358332 -42 28 C-40.28321107 22.08661589 -36.99610284 18.4348207 -32 15 C-23.44007925 12.25237112 -15.87136434 14.24854125 -8 18 C-4.95316424 12.1239596 -2.37904865 6.17540289 0 0 Z " fill="#E0BC59" transform="translate(49,7)"/>
<path d="M0 0 C5 0 10 0 15 0 C15.33 0.66 15.66 1.32 16 2 C16.33 1.34 16.66 0.68 17 0 C21.62 0 26.24 0 31 0 C31 2.31 31 4.62 31 7 C31.66 7 32.32 7 33 7 C33.33 4.69 33.66 2.38 34 0 C35.32 0.33 36.64 0.66 38 1 C38 2.65 38 4.3 38 6 C38.66 6.33 39.32 6.66 40 7 C40.33 4.69 40.66 2.38 41 0 C43.31 0 45.62 0 48 0 C48 1.98 48 3.96 48 6 C46.68 6 45.36 6 44 6 C45.32 6.33 46.64 6.66 48 7 C48.33 4.69 48.66 2.38 49 0 C54.33333333 0 59.66666667 0 65 0 C64.01 3.3 63.02 6.6 62 10 C61.01 10 60.02 10 59 10 C58.34 8.35 57.68 6.7 57 5 C57 5.99 57 6.98 57 8 C57 8.99 57 9.98 57 11 C56.278125 10.814375 55.55625 10.62875 54.8125 10.4375 C52.07999119 9.84542328 52.07999119 9.84542328 49.71020508 10.46655273 C46.6832639 11.06234289 43.90831412 11.11284884 40.82421875 11.09765625 C39.66728516 11.09443359 38.51035156 11.09121094 37.31835938 11.08789062 C36.12017578 11.07951172 34.92199219 11.07113281 33.6875 11.0625 C32.46998047 11.05798828 31.25246094 11.05347656 29.99804688 11.04882812 C26.99864774 11.03706578 23.99934911 11.02062826 21 11 C20.34 9.68 19.68 8.36 19 7 C18.34 8.32 17.68 9.64 17 11 C16.01 10.67 15.02 10.34 14 10 C13.67 9.34 13.34 8.68 13 8 C12.67 8.66 12.34 9.32 12 10 C10.0625 9.6875 10.0625 9.6875 8 9 C7.67 8.01 7.34 7.02 7 6 C6.67 7.65 6.34 9.3 6 11 C3.03 10.505 3.03 10.505 0 10 C0 6.7 0 3.4 0 0 Z M6 2 C7 4 7 4 7 4 Z M23 6 C23.33 6.66 23.66 7.32 24 8 C24.99 8 25.98 8 27 8 C27 7.34 27 6.68 27 6 C25.68 6 24.36 6 23 6 Z " fill="#F1F7F5" transform="translate(70,52)"/>
<path d="M0 0 C0.69287109 0.02255859 1.38574219 0.04511719 2.09960938 0.06835938 C3.79651573 0.12687339 5.49229861 0.2165452 7.1875 0.3125 C7.1875 4.2725 7.1875 8.2325 7.1875 12.3125 C6.1975 12.3125 5.2075 12.3125 4.1875 12.3125 C3.836875 11.178125 3.48625 10.04375 3.125 8.875 C2.39364319 6.91944436 2.39364319 6.91944436 1.1875 5.3125 C-2.40113524 4.2509058 -2.40113524 4.2509058 -5.8125 4.3125 C-6.3659764 6.38803648 -6.8125 8.15615964 -6.8125 10.3125 C-6.08417969 10.58320313 -5.35585937 10.85390625 -4.60546875 11.1328125 C-1.89300039 12.27849844 0.4156844 13.57579232 2.875 15.1875 C3.64199219 15.67992187 4.40898437 16.17234375 5.19921875 16.6796875 C7.36177944 18.45562143 8.24720871 19.69383931 9.1875 22.3125 C9.11535486 26.35262808 8.92885733 28.51149323 6.1484375 31.515625 C2.227764 33.89492554 -0.64539448 33.77066755 -5.125 33.5625 C-5.86556641 33.54445312 -6.60613281 33.52640625 -7.36914062 33.5078125 C-9.1841571 33.46097337 -10.99850166 33.38923789 -12.8125 33.3125 C-12.8125 29.3525 -12.8125 25.3925 -12.8125 21.3125 C-11.8225 21.3125 -10.8325 21.3125 -9.8125 21.3125 C-9.461875 22.446875 -9.11125 23.58125 -8.75 24.75 C-8.00833511 26.70014052 -8.00833511 26.70014052 -6.8125 28.3125 C-2.22575945 29.62252606 -2.22575945 29.62252606 2.1875 28.3125 C2.1875 26.3325 2.1875 24.3525 2.1875 22.3125 C1.5275 22.085625 0.8675 21.85875 0.1875 21.625 C-4.94077224 19.38138089 -9.67105642 17.02466537 -12.8125 12.3125 C-13.36550543 8.04645813 -13.39613235 6.08732226 -10.7734375 2.60546875 C-7.11135985 -0.23046737 -4.42663587 -0.27534099 0 0 Z " fill="#DEB755" transform="translate(169.8125,12.6875)"/>
<path d="M0 0 C3 1 3 1 4.39453125 3.66796875 C4.86246094 4.82941406 5.33039063 5.99085937 5.8125 7.1875 C6.19498901 8.11405396 6.19498901 8.11405396 6.58520508 9.05932617 C7.67703503 11.70518406 8.74296513 14.36127918 9.81152344 17.01660156 C10.59472449 18.95229484 11.39072413 20.88236394 12.1875 22.8125 C12.65542969 23.97394531 13.12335938 25.13539062 13.60546875 26.33203125 C14.76815117 29.30842386 14.76815117 29.30842386 18 30 C18 30.99 18 31.98 18 33 C13.71 33 9.42 33 5 33 C5 32.01 5 31.02 5 30 C5.66 30 6.32 30 7 30 C6.34 27.69 5.68 25.38 5 23 C1.37 22.67 -2.26 22.34 -6 22 C-6.66 24.64 -7.32 27.28 -8 30 C-7.01 30 -6.02 30 -5 30 C-5 30.99 -5 31.98 -5 33 C-8.63 33 -12.26 33 -16 33 C-16 32.01 -16 31.02 -16 30 C-15.01 29.67 -14.02 29.34 -13 29 C-11.46724396 26.23806648 -11.46724396 26.23806648 -10.1171875 22.8125 C-9.85745148 22.19479736 -9.59771545 21.57709473 -9.33010864 20.94067383 C-8.50140071 18.96457935 -7.68776274 16.98269941 -6.875 15 C-6.05222381 13.01862061 -5.22642425 11.03854624 -4.39846802 9.05932617 C-3.64932083 7.26448645 -2.90821752 5.46629568 -2.16748047 3.66796875 C-1 1 -1 1 0 0 Z M-1 10 C-1.99 12.97 -2.98 15.94 -4 19 C-1.36 19 1.28 19 4 19 C2.68 16.03 1.36 13.06 0 10 C-0.33 10 -0.66 10 -1 10 Z " fill="#E3BD59" transform="translate(137,13)"/>
<path d="M0 0 C8.91 0 17.82 0 27 0 C27 2.97 27 5.94 27 9 C26.01 9 25.02 9 24 9 C23.67 7.68 23.34 6.36 23 5 C20.69 4.67 18.38 4.34 16 4 C16.33 12.25 16.66 20.5 17 29 C18.32 29.66 19.64 30.32 21 31 C20.67 31.66 20.34 32.32 20 33 C15.38 33 10.76 33 6 33 C6 32.01 6 31.02 6 30 C7.32 30 8.64 30 10 30 C10 21.42 10 12.84 10 4 C8.02 4.33 6.04 4.66 4 5 C2.56362302 6.95163118 2.56362302 6.95163118 2 9 C1.34 9 0.68 9 0 9 C0 6.03 0 3.06 0 0 Z " fill="#C39342" transform="translate(66,13)"/>
<path d="M0 0 C4.95 0 9.9 0 15 0 C15 0.99 15 1.98 15 3 C13.35 3 11.7 3 10 3 C10 11.58 10 20.16 10 29 C19.36086936 29.00753319 19.36086936 29.00753319 23 25.5 C23.99 24.2625 23.99 24.2625 25 23 C25.66 22.67 26.32 22.34 27 22 C26.50639991 26.27786748 25.95216435 29.09567131 24 33 C16.08 33 8.16 33 0 33 C0 32.01 0 31.02 0 30 C0.99 29.67 1.98 29.34 3 29 C3 20.75 3 12.5 3 4 C2.01 3.67 1.02 3.34 0 3 C0 2.01 0 1.02 0 0 Z " fill="#EECF63" transform="translate(94,13)"/>
<path d="M0 0 C0.33 0.99 0.66 1.98 1 3 C-2.1055459 4.03518197 -3.03931837 3.93904933 -6.125 3.375 C-11.29767252 2.73368987 -15.37045468 3.62041371 -20 6 C-22.56336077 8.1882348 -23.7532201 9.58531985 -24.3359375 12.92578125 C-24.49554603 16.18547858 -24.09669402 18.70991795 -23.0625 21.8125 C-19.85388448 25.21557707 -16.61370483 26.39963105 -12 27 C-7.58787875 26.66327245 -3.32349884 25.9288506 1 25 C-2.95211827 29.16574628 -5.41373101 31.11833786 -11.1875 31.3125 C-17.81800164 31.21546827 -22.12059244 28.71945798 -26.9375 24.3125 C-29.53728085 20.13709439 -30.41555791 16.90358332 -30 12 C-28.28321107 6.08661589 -24.99610284 2.4348207 -20 -1 C-13.73374034 -3.05408512 -5.92315815 -2.88564115 0 0 Z " fill="#CC9944" transform="translate(37,23)"/>
<path d="M0 0 C1.65 0 3.3 0 5 0 C5.33 6.27 5.66 12.54 6 19 C7.32 19.66 8.64 20.32 10 21 C9.67 21.66 9.34 22.32 9 23 C4.38 23 -0.24 23 -5 23 C-5 22.01 -5 21.02 -5 20 C-3.68 20 -2.36 20 -1 20 C-1.00523682 19.42967041 -1.01047363 18.85934082 -1.01586914 18.27172852 C-1.03658221 15.70199677 -1.0496419 13.13228253 -1.0625 10.5625 C-1.07087891 9.66466797 -1.07925781 8.76683594 -1.08789062 7.84179688 C-1.09111328 6.98779297 -1.09433594 6.13378906 -1.09765625 5.25390625 C-1.10289307 4.46314697 -1.10812988 3.6723877 -1.11352539 2.85766602 C-1 1 -1 1 0 0 Z " fill="#D7A74B" transform="translate(77,23)"/>
<path d="M0 0 C0.33 0 0.66 0 1 0 C1 2.64 1 5.28 1 8 C8.05193909 7.9139073 8.05193909 7.9139073 14 4.5 C14.66 3.675 15.32 2.85 16 2 C16.66 1.67 17.32 1.34 18 1 C17.50639991 5.27786748 16.95216435 8.09567131 15 12 C7.08 12 -0.84 12 -9 12 C-9 11.01 -9 10.02 -9 9 C-8.01 8.67 -7.02 8.34 -6 8 C-5.67 6.02 -5.34 4.04 -5 2 C-3.35 2.33 -1.7 2.66 0 3 C0 2.01 0 1.02 0 0 Z " fill="#C29A46" transform="translate(103,34)"/>
<path d="M0 0 C3 1 3 1 4.640625 4.08984375 C5.18842218 5.36759397 5.72445938 6.65043572 6.25 7.9375 C6.53101563 8.58396484 6.81203125 9.23042969 7.1015625 9.89648438 C7.80373244 11.57022115 8.40920546 13.28378346 9 15 C8.67 15.66 8.34 16.32 8 17 C4 17 4 17 2.3125 15.6875 C1 14 1 14 0 11 C-0.24234375 11.56589844 -0.4846875 12.13179687 -0.734375 12.71484375 C-1.06953125 13.44832031 -1.4046875 14.18179687 -1.75 14.9375 C-2.23726562 16.03384766 -2.23726562 16.03384766 -2.734375 17.15234375 C-4 19 -4 19 -6.140625 19.75390625 C-7.06101563 19.87572266 -7.06101563 19.87572266 -8 20 C-7.3316356 15.62221316 -5.77089558 11.77628147 -4 7.75 C-3.72671875 7.11320312 -3.4534375 6.47640625 -3.171875 5.8203125 C-1.14074248 1.14074248 -1.14074248 1.14074248 0 0 Z " fill="#E2C25D" transform="translate(137,13)"/>
<path d="M0 0 C5.33333333 0 10.66666667 0 16 0 C15.01 3.3 14.02 6.6 13 10 C12.01 10 11.02 10 10 10 C9.34 8.35 8.68 6.7 8 5 C8 5.99 8 6.98 8 8 C8 8.99 8 9.98 8 11 C6.68 10.67 5.36 10.34 4 10 C4 9.34 4 8.68 4 8 C2.68 8.33 1.36 8.66 0 9 C0 6.03 0 3.06 0 0 Z " fill="#F4F9F8" transform="translate(119,52)"/>
<path d="M0 0 C0.69738281 0.01353516 1.39476562 0.02707031 2.11328125 0.04101562 C3.82585541 0.0762055 5.53802941 0.13000845 7.25 0.1875 C7.25 4.1475 7.25 8.1075 7.25 12.1875 C6.26 12.1875 5.27 12.1875 4.25 12.1875 C3.899375 11.053125 3.54875 9.91875 3.1875 8.75 C2.45614319 6.79444436 2.45614319 6.79444436 1.25 5.1875 C-2.33863524 4.1259058 -2.33863524 4.1259058 -5.75 4.1875 C-6.08 5.8375 -6.41 7.4875 -6.75 9.1875 C-9.72 8.6925 -9.72 8.6925 -12.75 8.1875 C-12.44163178 5.33509396 -12.18537337 3.64899577 -10.1875 1.53125 C-6.82286031 -0.32361547 -3.73093605 -0.13752978 0 0 Z " fill="#C08D3F" transform="translate(169.75,12.8125)"/>
<path d="M0 0 C1.65 0 3.3 0 5 0 C5 4.62 5 9.24 5 14 C3.35 14 1.7 14 0 14 C-1.31164842 11.37670316 -1.13262846 9.36301375 -1.125 6.4375 C-1.12757812 5.44621094 -1.13015625 4.45492188 -1.1328125 3.43359375 C-1 1 -1 1 0 0 Z " fill="#EBC85E" transform="translate(77,23)"/>
<path d="M0 0 C0.99 0.33 1.98 0.66 3 1 C2.67 3.64 2.34 6.28 2 9 C3.32 9.33 4.64 9.66 6 10 C6 10.66 6 11.32 6 12 C1.71 12 -2.58 12 -7 12 C-6 9 -6 9 -4.0625 7.125 C-1.85208757 4.84760538 -0.99430634 2.98291902 0 0 Z " fill="#CDA149" transform="translate(38,33)"/>
<path d="M0 0 C4.95 0 9.9 0 15 0 C15 0.99 15 1.98 15 3 C13.35 3 11.7 3 10 3 C10 3.99 10 4.98 10 6 C8.02 6 6.04 6 4 6 C3.67 5.34 3.34 4.68 3 4 C2.01 3.67 1.02 3.34 0 3 C0 2.01 0 1.02 0 0 Z " fill="#BE8A3E" transform="translate(94,13)"/>
<path d="M0 0 C0.433125 0.639375 0.86625 1.27875 1.3125 1.9375 C2.91239177 4.26746003 2.91239177 4.26746003 6 5 C6 5.99 6 6.98 6 8 C1.71 8 -2.58 8 -7 8 C-7 7.01 -7 6.02 -7 5 C-6.34 5 -5.68 5 -5 5 C-5 4.34 -5 3.68 -5 3 C-3.35 2.67 -1.7 2.34 0 2 C0 1.34 0 0.68 0 0 Z " fill="#CF9F46" transform="translate(149,38)"/>
<path d="M0 0 C0.33 0 0.66 0 1 0 C1 2.64 1 5.28 1 8 C1.66 8.33 2.32 8.66 3 9 C2 10 2 10 -1.5625 10.0625 C-2.696875 10.041875 -3.83125 10.02125 -5 10 C-5 7.36 -5 4.72 -5 2 C-2.525 2.495 -2.525 2.495 0 3 C0 2.01 0 1.02 0 0 Z " fill="#D7A84B" transform="translate(103,34)"/>
<path d="M0 0 C1.32 0.33 2.64 0.66 4 1 C5.25 4.0625 5.25 4.0625 6 7 C3.03 7.66 0.06 8.32 -3 9 C-2.01 6.03 -1.02 3.06 0 0 Z " fill="#C29342" transform="translate(49,7)"/>
<path d="M0 0 C2.31 0 4.62 0 7 0 C7 2.31 7 4.62 7 7 C6.401875 6.814375 5.80375 6.62875 5.1875 6.4375 C2.83591238 5.77906313 2.83591238 5.77906313 0 7 C0 4.69 0 2.38 0 0 Z " fill="#F1F6F4" transform="translate(116,76)"/>
<path d="M0 0 C0.99 0.33 1.98 0.66 3 1 C4.1875 4.5625 4.1875 4.5625 5 8 C2.36 8 -0.28 8 -3 8 C-1.125 1.125 -1.125 1.125 0 0 Z " fill="#D9AD4E" transform="translate(137,13)"/>
<path d="M0 0 C0.99 0 1.98 0 3 0 C3 2.97 3 5.94 3 9 C0.625 10.0625 0.625 10.0625 -2 11 C-2.66 10.67 -3.32 10.34 -4 10 C-4 9.01 -4 8.02 -4 7 C-2.68 7 -1.36 7 0 7 C0 4.69 0 2.38 0 0 Z " fill="#F0F7F5" transform="translate(66,52)"/>
<path d="M0 0 C2.31 0 4.62 0 7 0 C7 1.98 7 3.96 7 6 C5.35 6 3.7 6 2 6 C1.67 6.66 1.34 7.32 1 8 C0 7 0 7 -0.0625 3.4375 C-0.041875 2.303125 -0.02125 1.16875 0 0 Z " fill="#F6F9F8" transform="translate(111,52)"/>
<path d="M0 0 C6.27968807 1.23351016 10.75950071 3.3483755 16 7 C15.01 7.495 15.01 7.495 14 8 C12.66212411 7.34249689 11.32948552 6.6743074 10 6 C7.64435404 5.55188863 7.64435404 5.55188863 5.25 5.375 C4.05117187 5.26285156 4.05117187 5.26285156 2.828125 5.1484375 C1.92320312 5.07496094 1.92320312 5.07496094 1 5 C0.67 3.35 0.34 1.7 0 0 Z " fill="#EAD56A" transform="translate(157,21)"/>
<path d="M0 0 C1.98 0 3.96 0 6 0 C6 2.31 6 4.62 6 7 C4.02 7 2.04 7 0 7 C0 4.69 0 2.38 0 0 Z " fill="#F0F5F4" transform="translate(134,76)"/>
<path d="M0 0 C1.53202706 0.25195908 3.05257128 0.57596777 4.5625 0.9375 C5.38878906 1.13214844 6.21507813 1.32679687 7.06640625 1.52734375 C8.02353516 1.76130859 8.02353516 1.76130859 9 2 C8.67 2.99 8.34 3.98 8 5 C4.04 5 0.08 5 -4 5 C-4 4.01 -4 3.02 -4 2 C-4.66 1.67 -5.32 1.34 -6 1 C-4.7934375 1.0309375 -4.7934375 1.0309375 -3.5625 1.0625 C-1.06645115 1.29633621 -1.06645115 1.29633621 0 0 Z " fill="#DDEAE6" transform="translate(113,58)"/>
<path d="M0 0 C1.66611905 -0.042721 3.33382885 -0.04063832 5 0 C6 1 6 1 6.125 3.5 C6 6 6 6 5 7 C3.33382885 7.04063832 1.66611905 7.042721 0 7 C0 4.69 0 2.38 0 0 Z " fill="#F2F7F5" transform="translate(68,76)"/>
<path d="M0 0 C4.95 0 9.9 0 15 0 C11.5387369 1.73063155 10.42635617 2.19945768 6.875 2.375 C2.96514728 2.53862218 2.96514728 2.53862218 1.5 5.0625 C1.335 5.701875 1.17 6.34125 1 7 C0.34 7 -0.32 7 -1 7 C-0.67 4.69 -0.34 2.38 0 0 Z " fill="#CC923E" transform="translate(67,15)"/>
<path d="M0 0 C1.65 0 3.3 0 5 0 C6.125 4.75 6.125 4.75 5 7 C3.35 7 1.7 7 0 7 C0 4.69 0 2.38 0 0 Z " fill="#F2F6F5" transform="translate(94,76)"/>
<path d="M0 0 C1.67542976 0.28604898 3.34385343 0.61781233 5 1 C5 2.65 5 4.3 5 6 C3.34385343 6.38218767 1.67542976 6.71395102 0 7 C-1 6 -1 6 -1.125 3.5 C-1 1 -1 1 0 0 Z " fill="#F1F6F5" transform="translate(45,76)"/>
<path d="M0 0 C2.475 0.99 2.475 0.99 5 2 C4.67 2.66 4.34 3.32 4 4 C-0.62 4 -5.24 4 -10 4 C-10 3.01 -10 2.02 -10 1 C-8.68 1 -7.36 1 -6 1 C-6 1.66 -6 2.32 -6 3 C-4.02 2.67 -2.04 2.34 0 2 C0 1.34 0 0.68 0 0 Z " fill="#B89444" transform="translate(82,42)"/>
<path d="M0 0 C1.2065625 0.0309375 1.2065625 0.0309375 2.4375 0.0625 C2.4375 2.0425 2.4375 4.0225 2.4375 6.0625 C0.7875 6.3925 -0.8625 6.7225 -2.5625 7.0625 C-2.94383299 5.0710944 -3.27575582 3.06970923 -3.5625 1.0625 C-2.5625 0.0625 -2.5625 0.0625 0 0 Z M-0.5625 2.0625 C0.4375 5.0625 0.4375 5.0625 0.4375 5.0625 Z " fill="#F3F7F6" transform="translate(55.5625,75.9375)"/>
<path d="M0 0 C1.32 0 2.64 0 4 0 C4.33 2.31 4.66 4.62 5 7 C3.35 7 1.7 7 0 7 C-1.125 2.25 -1.125 2.25 0 0 Z " fill="#EEF4F2" transform="translate(82,76)"/>
<path d="M0 0 C1.65 0 3.3 0 5 0 C5.625 1.8125 5.625 1.8125 6 4 C5.01 5.485 5.01 5.485 4 7 C2.68 7 1.36 7 0 7 C-1.125 2.25 -1.125 2.25 0 0 Z M2 2 C3 5 3 5 3 5 Z " fill="#E9F0EE" transform="translate(126,76)"/>
<path d="M0 0 C1.2065625 0.0309375 1.2065625 0.0309375 2.4375 0.0625 C2.4375 2.0425 2.4375 4.0225 2.4375 6.0625 C1.81875 6.206875 1.2 6.35125 0.5625 6.5 C-1.59508322 6.99402225 -1.59508322 6.99402225 -3.5625 8.0625 C-3.60491723 5.72955225 -3.60342937 3.39547433 -3.5625 1.0625 C-2.5625 0.0625 -2.5625 0.0625 0 0 Z " fill="#F2D769" transform="translate(79.5625,22.9375)"/>
<path d="M0 0 C1.32 0 2.64 0 4 0 C2.125 5.75 2.125 5.75 1 8 C-1.0625 8.625 -1.0625 8.625 -3 9 C-2.44271087 5.65626525 -1.64826111 2.96687001 0 0 Z " fill="#EED66B" transform="translate(132,24)"/>
<path d="M0 0 C0.99 0 1.98 0 3 0 C3.66 2.31 4.32 4.62 5 7 C2.69 7 0.38 7 -2 7 C-1.34 4.69 -0.68 2.38 0 0 Z " fill="#F1F6F4" transform="translate(109,76)"/>
<path d="M0 0 C1.65 0 3.3 0 5 0 C5 1.98 5 3.96 5 6 C3.35 6.33 1.7 6.66 0 7 C0 4.69 0 2.38 0 0 Z M2 2 C3 5 3 5 3 5 Z " fill="#F4F8F7" transform="translate(143,76)"/>
<path d="M0 0 C2.3125 -0.25 2.3125 -0.25 5 0 C6.8125 2 6.8125 2 8 4 C7.67 4.66 7.34 5.32 7 6 C3 6 3 6 1.25 4.6875 C0 3 0 3 0 0 Z " fill="#F3DB6C" transform="translate(138,24)"/>
<path d="M0 0 C0.66 1.32 1.32 2.64 2 4 C3.65 3.67 5.3 3.34 7 3 C7 3.99 7 4.98 7 6 C3.37 6 -0.26 6 -4 6 C-4 5.01 -4 4.02 -4 3 C-3.01 2.67 -2.02 2.34 -1 2 C-0.67 1.34 -0.34 0.68 0 0 Z " fill="#BD9645" transform="translate(125,40)"/>
<path d="M0 0 C1.9375 0.3125 1.9375 0.3125 4 1 C5.28317892 4.84953676 5.06643637 7.94738135 5 12 C4.01 11.67 3.02 11.34 2 11 C2 9.02 2 7.04 2 5 C1.34 4.67 0.68 4.34 0 4 C0 2.68 0 1.36 0 0 Z " fill="#DDBD5C" transform="translate(37,24)"/>
<path d="M0 0 C0.99 0 1.98 0 3 0 C2.34 2.31 1.68 4.62 1 7 C-0.32 7.33 -1.64 7.66 -3 8 C-2.25 2.25 -2.25 2.25 0 0 Z " fill="#F2DA6B" transform="translate(45,20)"/>
<path d="M0 0 C0.33 0 0.66 0 1 0 C1 2.97 1 5.94 1 9 C0.01 9 -0.98 9 -2 9 C-2.99 6.36 -3.98 3.72 -5 1 C-2.525 1.495 -2.525 1.495 0 2 C0 1.34 0 0.68 0 0 Z " fill="#D8B255" transform="translate(176,16)"/>
<path d="M0 0 C-0.33 1.65 -0.66 3.3 -1 5 C-3.97 4.505 -3.97 4.505 -7 4 C-6.625 2.0625 -6.625 2.0625 -6 0 C-3.50907189 -1.24546405 -2.58919267 -0.7767578 0 0 Z " fill="#D5A84C" transform="translate(164,17)"/>
<path d="M0 0 C1.98 0 3.96 0 6 0 C6 1.65 6 3.3 6 5 C3.03 4.505 3.03 4.505 0 4 C0 2.68 0 1.36 0 0 Z " fill="#E1B855" transform="translate(98,19)"/>
<path d="M0 0 C1.65 0 3.3 0 5 0 C5 1.32 5 2.64 5 4 C3.02 4.33 1.04 4.66 -1 5 C-0.67 3.35 -0.34 1.7 0 0 Z " fill="#E1B855" transform="translate(77,19)"/>
<path d="M0 0 C2.31 0 4.62 0 7 0 C7.33 0.99 7.66 1.98 8 3 C6.35 3 4.7 3 3 3 C2.67 3.99 2.34 4.98 2 6 C1.34 4.02 0.68 2.04 0 0 Z " fill="#F2D567" transform="translate(50,19)"/>
<path d="M0 0 C0.99 0.33 1.98 0.66 3 1 C3.6875 3.0625 3.6875 3.0625 4 5 C2.02 5 0.04 5 -2 5 C-1.34 3.35 -0.68 1.7 0 0 Z " fill="#C59442" transform="translate(137,13)"/>
<path d="M0 0 C0.99 0 1.98 0 3 0 C3 2.31 3 4.62 3 7 C2.01 7 1.02 7 0 7 C0 4.69 0 2.38 0 0 Z " fill="#E9F2EF" transform="translate(102,76)"/>
<path d="M0 0 C0.66 0 1.32 0 2 0 C3.20628008 2.33214148 4.1654738 4.49642139 5 7 C3.35 7 1.7 7 0 7 C0 4.69 0 2.38 0 0 Z " fill="#EDF1F0" transform="translate(61,76)"/>
<path d="M0 0 C0 0.66 0 1.32 0 2 C-2.97 2.66 -5.94 3.32 -9 4 C-9 3.34 -9 2.68 -9 2 C-5.5647952 -0.29013653 -4.014669 -0.17842973 0 0 Z " fill="#C9A34B" transform="translate(55,12)"/>
<path d="M0 0 C0.33 0 0.66 0 1 0 C1 1.65 1 3.3 1 5 C-0.65 5.33 -2.3 5.66 -4 6 C-4.33 4.68 -4.66 3.36 -5 2 C-3.35 2 -1.7 2 0 2 C0 1.34 0 0.68 0 0 Z " fill="#DAAF51" transform="translate(148,35)"/>
<path d="M0 0 C1.31471568 0.28151087 2.62675767 0.57553339 3.9375 0.875 C4.66839844 1.03742187 5.39929688 1.19984375 6.15234375 1.3671875 C8 2 8 2 9 4 C6.03 4 3.06 4 0 4 C0 2.68 0 1.36 0 0 Z " fill="#E5C760" transform="translate(157,21)"/>
<path d="M0 0 C0.66 0 1.32 0 2 0 C2 0.66 2 1.32 2 2 C5.63 2 9.26 2 13 2 C13 2.33 13 2.66 13 3 C8.71 3 4.42 3 0 3 C0 2.01 0 1.02 0 0 Z " fill="#AE8F44" transform="translate(142,43)"/>
<path d="M0 0 C2.31 0 4.62 0 7 0 C7 0.99 7 1.98 7 3 C5.02 3 3.04 3 1 3 C0.67 2.01 0.34 1.02 0 0 Z " fill="#D29F46" transform="translate(97,16)"/>
<path d="M0 0 C0.99 0 1.98 0 3 0 C2.34 1.98 1.68 3.96 1 6 C0.34 6 -0.32 6 -1 6 C-1.042721 4.33388095 -1.04063832 2.66617115 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z " fill="#D0A44B" transform="translate(67,16)"/>
</svg>
`]
